<template>
  <div class="ContractManagement">
    <div class="ContractManagement-content">
      <div class="serach_box">
        <a-form layout="inline">
          <a-form-item>
            <a-select
              style="width: 120px"
              v-model="persionnal_status"
              placeholder="员工状态"
            >
              <a-select-option
                v-for="(item, k) in persionnal_status_arr"
                :key="k"
                :value="item.value"
                >{{ item.name }}</a-select-option
              >
            </a-select>
          </a-form-item>
          <a-form-item>
            <a-tree-select
              style="min-width: 220px;max-width:360px"
              :treeData="department_arr"
              :value="posts_type[0] ? posts_type : undefined"
              @change="onChangeTree"
              treeNodeLabelProp="title"
              treeCheckable
              :showCheckedStrategy="SHOW_PARENT"
              placeholder="请选择部门"
            />
          </a-form-item>
          <a-form-item>
            <a-select
              style="width: 120px"
              v-model="needs_type"
              @change="change"
              placeholder="虚拟人员"
            >
              <a-select-option value="0">不显示</a-select-option>
              <a-select-option value="1">显示</a-select-option>
            </a-select>
          </a-form-item>

          <a-form-item>
            <a-range-picker
              style="width:220px;"
              format="YYYY-MM-DD"
              :placeholder="['起始时间', '截止时间']"
              allowClear
              @change="changeTime"
            />
          </a-form-item>
          <a-form-item>
            <a-select
              style="width: 120px"
              v-model="pressing_type"
              placeholder="快速选择"
            >
              <a-select-option
                v-for="(item, k) in pressing_type_arr"
                :key="k"
                :value="item.value"
                >{{ item.name }}</a-select-option
              >
            </a-select>
          </a-form-item>
          <a-form-item>
            <a-input
              placeholder="请输入"
              style="width:200px;"
              v-model="classify_name"
            />
          </a-form-item>
          <a-form-item>
            <a-button style="margin-right:20px" @click="resetBtn()"
              >重置</a-button
            >
            <a-button type="primary" @click="serachBtn()">查询</a-button>
          </a-form-item>
        </a-form>
      </div>
      <a-table
        :columns="columns"
        :dataSource="data"
        :pagination="false"
        :customRow="rowClick"
        :scroll="{ x: '150%' }"
        style="margin-top:10px;border:1px solid #efefef"
      >
        <template slot="action" slot-scope="columnsInfo">
          <a-dropdown :trigger="['click']">
            <a-icon type="ellipsis" />
            <a-menu slot="overlay">
              <a-menu-item key="0" @click="operationFn(columnsInfo, 'add')">
                员工转正申请
              </a-menu-item>
              <a-menu-item key="1" @click="operationFn(columnsInfo, 'addNext')">
                合同续签申请
              </a-menu-item>
              <!-- <a-menu-divider />
                        <a-menu-item key="3">3rd menu item</a-menu-item> -->
            </a-menu>
          </a-dropdown>
          <!-- <a-tooltip placement="left" overlayClassName="tooltipBg">
                        <template slot="title">
                            <div style="padding: 0px 4px;">
                                <p class="hoverColor" @click="stopFn(columnsInfo)">停止招聘{{columnsInfo.key}}</p>
                                <p class="hoverColor" @click="delFn(columnsInfo)" style="margin-top:2px;">删除{{columnsInfo.age}}</p>
                            </div>
                        </template>
                        <a-icon type="ellipsis" style="font-size: 28px;font-weight: 800;" />
                    </a-tooltip> -->
        </template>
        <template slot="footer">
          <div style="font-weight:bold;">
            <span style="margin-right:20px;"> 合计:</span> {{ '36' }}
          </div>
        </template>
      </a-table>
      <div style="margin: 20px 0;float:right">
        <a-pagination
          showQuickJumper
          :defaultCurrent="1"
          :total="total"
          :pageSize="pagesize"
          @change="pageChange"
        />
      </div>
    </div>
    <a-spin style="position:fixed;left:50%;top:150px" v-if="spinning">
      <a-icon
        slot="indicator"
        type="loading"
        :spinning="spinning"
        style="font-size: 44px;color:red;"
        spin
      />
    </a-spin>
    <a-drawer
      :title="controlType == 'add' ? '试用期转正' : '合同续签'"
      width="360"
      placement="right"
      @close="onClose"
      :visible="controlType ? true : false"
    >
      <div v-if="controlType == 'add'">
        <a-form style="padding-bottom:50px;">
          <a-form-item v-bind="formItemLayout" label="转正类型">
            <a-select v-model="CVsource" placeholder="请选择">
              <a-select-option
                v-for="item in CVsource_arr"
                :key="item.key"
                :value="item.key"
                >{{ item.name }}</a-select-option
              >
            </a-select>
          </a-form-item>
          <a-form-item v-bind="formItemLayout" label="指派人">
            <a-input type="text" v-model="CVname" placeholder="请输入姓名" />
          </a-form-item>
          <a-form-item v-bind="formItemLayout" label="生效日期">
            <a-date-picker @change="onChange" style="width:100%;" />
            <!-- :disabledDate="disabledDate" -->
          </a-form-item>
          <a-form-item v-bind="formItemLayout" label="紧急程度">
            <a-select v-model="pressing_type" placeholder="请选择">
              <a-select-option
                v-for="(item, k) in pressing_type_arr"
                :key="k"
                :value="item.FieldValue"
                >{{ item.FieldName }}</a-select-option
              >
            </a-select>
          </a-form-item>
          <!-- <a-divider orientation="left">发送消息通知</a-divider> -->
          <h3>发送消息通知</h3>
          <a-form-item v-bind="formItemLayout2" label="员工转正申请表">
          </a-form-item>
          <a-form-item v-bind="formItemLayout2" label="员工转正评估表(上级)">
          </a-form-item>
          <a-form-item v-bind="formItemLayout2" label="员工转正评估表(上上级)">
          </a-form-item>
          <a-form-item v-bind="formItemLayout2" label="员工转正评估表(HR)">
            {{ '一个人名' }}
          </a-form-item>
        </a-form>
      </div>
      <div v-else>
        <a-form style="padding-bottom:50px;">
          <a-form-item v-bind="formItemLayout" label="合同类型">
            <a-select v-model="CVpost" placeholder="请选择">
              <a-select-option
                v-for="(item, k) in CVpost_arr"
                :key="k"
                :value="item.value"
                >{{ item.name }}</a-select-option
              >
            </a-select>
          </a-form-item>

          <a-form-item v-bind="formItemLayout" label="直属上级审批">
            <person v-bind="contackObj" @getData="contackPerson"></person>
          </a-form-item>
          <a-form-item v-bind="formItemLayout" label="人力审批">
            <person v-bind="contackObj" @getData="contackPerson"></person>
          </a-form-item>
          <a-form-item v-bind="formItemLayout" label="续签批次">
            <a-select v-model="CVpost" placeholder="请选择">
              <a-select-option
                v-for="item in CVpost_arr"
                :key="item.key"
                :value="item.key"
                >{{ item.name }}</a-select-option
              >
            </a-select>
          </a-form-item>
          <a-form-item v-bind="formItemLayout" label="合同期限">
            <a-select v-model="CVpost" placeholder="请选择">
              <a-select-option
                v-for="item in CVpost_arr"
                :key="item.key"
                :value="item.key"
                >{{ item.name }}</a-select-option
              >
            </a-select>
          </a-form-item>
          <a-form-item v-bind="formItemLayout" label="紧急程度">
            <a-select v-model="pressing_type" placeholder="请选择">
              <a-select-option
                v-for="(item, k) in pressing_type_arr"
                :key="k"
                :value="item.FieldValue"
                >{{ item.FieldName }}</a-select-option
              >
            </a-select>
          </a-form-item>
          <a-form-item v-bind="formItemLayout" label="合同主体">
            <a-select v-model="CVpost" placeholder="请选择">
              <a-select-option
                v-for="item in CVpost_arr"
                :key="item.key"
                :value="item.key"
                >{{ item.name }}</a-select-option
              >
            </a-select>
          </a-form-item>
          <a-form-item v-bind="formItemLayout" label="合同起止日期">
            <a-range-picker
              format="YYYY-MM-DD"
              :placeholder="['合同开始时间', '合同结束时间']"
              allowClear
              @change="contractChange"
            />
          </a-form-item>
          <a-form-item v-bind="formItemLayout" label="经办人">
            {{ __USER__.name }}
          </a-form-item>
        </a-form>
      </div>
      <div class="drawer-btns">
        <a-button type="default" class="drawer-btn">添 加</a-button
        ><a-button class="drawer-btn" type="primary">确 定</a-button>
      </div>
    </a-drawer>
  </div>
</template>
<script>
const columns = [
  { title: '姓名', width: 180, dataIndex: 'name', key: 'name', fixed: 'left' },
  { title: '部门', width: 100, dataIndex: 'age', key: 'age', fixed: 'left' },
  { title: '岗位', dataIndex: 'address', key: '1' },
  { title: '合同类型', dataIndex: 'address', key: '2' },
  { title: '合同开始时间', dataIndex: 'address', key: '3' },
  { title: '合同结束时间', dataIndex: 'address', key: '4' },
  { title: '合同状态', width: 180, dataIndex: 'address', key: '5' },
  { title: '创建人', dataIndex: 'address', key: '6' },
  { title: '创建时间', dataIndex: 'address', key: '7' },
  { title: '续签次数', dataIndex: 'address', key: '8' },
  { title: '合同主体', dataIndex: 'address', key: '9' },
  { title: '合同到期倒计时', dataIndex: 'address', key: '10' },
  {
    title: 'Action',
    key: 'operation',
    fixed: 'right',
    width: 100,
    scopedSlots: { customRender: 'action' }
  }
]

const data = [
  {
    key: '1',
    name: 'John Brown',
    age: 32,
    address: 'New York Park'
  },
  {
    key: '2',
    name: 'Jim Green',
    age: 40,
    address: 'London Park'
  }
]
import person from '@/components/SelectPersonnel/SelectPersonnel' //选人

import { TreeSelect } from 'ant-design-vue'
const SHOW_PARENT = TreeSelect.SHOW_PARENT
import moment from 'moment'
import {
  __dateTransation, //插件时间格式化公共方法
  __persionnal_Status,
  __arr_experience,
  __fast_Select
} from '@/assets/js/public_ren'
export default {
  name: 'ContractManagement',
  components: { person },
  data () {
    return {
      SHOW_PARENT,
      formItemLayout: {
        labelCol: { span: 7 },
        wrapperCol: { span: 17 }
      },
      formItemLayout2: {
        labelCol: { span: 12 },
        wrapperCol: { span: 12 }
      },

      treeData: [],

      spinning: false,
      clientH: '400px',
      //table
      data,
      columns,
      //搜索
      department_arr: [], //部门
      posts_type: [],
      persionnal_status_arr: __persionnal_Status(), //员工状态
      persionnal_status: undefined,
      pressing_type_arr: [], //紧急程度
      pressing_type: undefined,

      needs_type: undefined, //虚拟人员 0不 1为显示
      classify_name: '', //input输入框
      startDate: '',
      endDate: '',
      pagesize: 15, //每页5条
      index: 1, //页码
      total: 1, //总数

      controlType: false,
      contackObj: {
        //选联系人
        rank: 0, //职级 0为不选，1为选
        nums: 1, //选的数量
        department: 0, //0不选部门，1为都选（人 部门都选），2为只选部门，选人
        contacts: ' ', //常用联系人 不要常用联系人传空''
        selectArr: [] //要传入的数据
      },
      contack_ren: [], //选联系人 以及部门负责人

      education_arr: __arr_experience(), //
      education: [] //学历
    }
  },
  mounted () {
    let domHeight = document.documentElement.clientHeight
    this.clientH = domHeight - 248 + 'px'
    if (window.sessionStorage.getItem('slePerson')) {
      this.treeData = JSON.parse(window.sessionStorage.getItem('slePerson'))
    } else {
      this.$axios.get(8002, { Pid: 0 }, res => {
        if (res.data.list) {
          this.treeData = res.data.list
          window.sessionStorage.setItem(
            'slePerson',
            JSON.stringify(res.data.list)
          )
        } else {
          this.$message.error('获取数据失败!')
        }
      })
    }
    if (sessionStorage.getItem('department_arr')) {
      this.department_arr = JSON.parse(sessionStorage.getItem('department_arr'))
    } else {
      this.$axios.get(8012, {}, res => {
        //改变权限设置方发
        if (!res.data) {
          return false
        }
        if (res.data.code == 1) {
          this.department_arr = res.data.data
          sessionStorage.setItem(
            'department_arr',
            JSON.stringify(res.data.data)
          )
        }
      })
    }
    //紧急程度
    if (sessionStorage.getItem('pressing_type_arr')) {
      this.pressing_type_arr = JSON.parse(
        sessionStorage.getItem('pressing_type_arr')
      )
    } else {
      this.$axios.get(8006, {}, res => {
        if (res.data.code == 1) {
          this.pressing_type_arr = res.data.data
        } else {
          this.$message.error(res.data.msg)
        }
      })
    }

    console.log(this.pressing_type_arr)
    //获取需求类型
    this.$axios.get(8004, {}, res => {
      if (res.data.code == 1) {
        this.needs_type_arr = res.data.data
      } else {
        this.$message.error(res.data.msg)
      }
    })
    // //获取完成状态
    // this.$axios.get(8005,{},res=>{
    //     if (res.data.code==1) {
    //         this.persionnal_status_arr = res.data.data;
    //     }else{
    //         this.$message.error(res.data.msg);
    //     }
    // });
    //紧急程度

    this.tableFn()
  },
  methods: {
    onChange (date, dateString) {
      console.log(date, dateString)
    },
    // disabledDate(current) {
    //     console.log(current)
    //     // Can not select days before today and today
    //     return current && current < moment().endOf('day');
    // },
    //选择岗位
    onChangeTree (value) {
      console.log(value)
      this.posts_type = value
    },
    change (val) {
      console.log(
        this.posts_type,
        this.pressing_type,
        this.pressing_type,
        this.needs_type,
        this.classify_name,
        this.startDate,
        this.endDate,
        this.index
      )
      console.log(val)
    },
    //搜索时间
    changeTime (value) {
      console.log(__dateTransation(value))
    },
    //分页
    pageChange (pageNumber) {
      console.log(pageNumber)
      this.index = pageNumber
      this.tableFn()
    },
    //重置
    resetBtn () {
      console.log(
        this.posts_type,
        this.needs_type,
        this.persionnal_status,
        this.pressing_type
      )
      this.persionnal_status = undefined
      this.needs_type = undefined
      this.posts_type = ''
      this.pressing_type = undefined
      this.classify_name = ''
      this.startDate = ''
      this.endDate = ''
      this.index = 1
      // this.tableFn(1,this.page,this.classify_type,this.classify_name,this.startDate,this.endDate);
    },
    //搜索按钮
    serachBtn () {
      console.log(
        this.posts_type,
        this.pressing_type,
        this.pressing_type,
        this.needs_type,
        this.classify_name,
        this.startDate,
        this.endDate
      )
      if (
        !this.posts_type ||
        !this.pressing_type ||
        !this.pressing_type ||
        !this.needs_type ||
        !this.classify_name ||
        !this.startDate ||
        !this.endDate
      ) {
        that.$message.error('都为空不请求!')
        return false
      }
      this.tableFn()
    },
    // 点击table
    rowClick: (record, index) => {
      return {
        on: {
          click: () => {
            console.log(record, index)
          }
        }
      }
    },
    //... 操作
    operationFn (obj, type) {
      this.controlType = type
      console.log(obj)
    },
    onClose () {
      this.controlType = false
    },
    //选人
    contackPerson (value) {
      // console.log(`选联系人 ${value}`);
      this.contack_ren = value
    },
    contractChange (value) {
      console.log(__dateTransation(value))
    },
    //请求ajax
    tableFn (
      index = 1,
      persionnal_status = '',
      needs_type = '',
      posts_type = '',
      pressing_type = '',
      startDate = '',
      endDate = ''
    ) {
      let that = this
      that.spinning = true
      that.$axios.get(
        9971,
        {
          Status: that.persionnal_status, //完成状态
          PositionLabel: that.posts_type, //职位Id
          DemandType: that.needs_type, //需求类型
          StartDate: that.startDate,
          EndDate: that.endDate,
          UrgentLavel: pressing_type, //紧急程度
          DemandUserId: JSON.parse(window.sessionStorage.getItem('userInfo'))
            .uid,
          p: that.index, //页码
          pagesize: 15
        },
        res => {
          that.spinning = false
          console.log(res.data)
          if (res.data.code != 1) {
            that.$message.error(res.data.msg)
            return false
          }
        }
      )
    }
  }
}
</script>
<style lang="less" scoped>
.ContractManagement {
  margin-top: 21px;
  .ContractManagement-content {
    margin-left: 10px;
    width: 100%;
    .serach_box {
      position: relative;
      .map-position {
        position: absolute;
        top: 4px;
        right: 0px;
      }
      .item-label {
        margin-right: 12px;
        margin-bottom: 12px;
        width: 160px;
      }
    }
  }
}

.ant-form-item {
  margin-bottom: 14px;
}
</style>
